/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
// Scroll To Top

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const getIndex = (val, arr) => {
  const locate = (element) => element.name === val;
  return arr.findIndex(locate);
};

const handleCheckboxChange = (e, arr, func) => {
  let newArray = [...arr, e.target.id];
  if (arr.includes(e.target.id)) {
    // eslint-disable-next-line no-unused-vars
    newArray = newArray.filter((item) => item !== e.target.id);
  }
  func(newArray);
};

const isPresent = (obj, array) => {
  if (array) {
    const locate = (element) => element === obj;
    return array.some(locate);
  }
  return false;
};

const duration = (seconds) => {
  const val = new Date(seconds * 1000).toISOString();
  if (val.substring(12, 13) === '0') {
    return val.substring(14, 19);
  } 
    return val.substring(12, 19);
};
const setTypeFunc = (e, func1, func2) => {
  func1(e.name);
  func2(e);
};

const showNotification = (setFunc) => {
  setFunc(true);
  setTimeout(() => {
    setFunc(false);
  }, 4000);
};

const deleteSeries = async (e, id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Method': 'DELETE',
      'X-Requested-With': 'XMLHttpRequest',
      
    },
  };
  e.preventDefault();

  const response = await fetch(
    `${process.env.REACT_APP_API_PCDLSUB_A}3?SeriesId=${id}`,
    requestOptions,
  );
  const json = await response.json();

  if (!response.ok) {
    console.log(json.error);
  } else if (response.ok) {
    scrollToTop();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
};

const deleteSeason = async (e, SeriesId, SeasonId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Method': 'DELETE',
      'X-Requested-With': 'XMLHttpRequest',
      
    },
    body: JSON.stringify({
      SeriesId,
      SeasonId,
    }),
  };

  e.preventDefault();

  const response = await fetch(
    `${process.env.REACT_APP_API_PCDLSUB_A}5`,
    requestOptions,
  );
  const json = await response.json();

  if (!response.ok) {
    console.log(json.error);
  } else if (response.ok) {
    scrollToTop();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
};

const deleteMessage = async (e, SeriesId, SeasonId, MessagesId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Method': 'DELETE',
      'X-Requested-With': 'XMLHttpRequest',
      
    },
    body: JSON.stringify({
      SeriesId,
      SeasonId,
      MessagesId,
    }),
  };

  e.preventDefault();

  const response = await fetch(
    `${process.env.REACT_APP_API_PCDLSUB_A}7`,
    requestOptions,
  );
  const json = await response.json();

  if (!response.ok) {
    console.log(json.error);
  } else if (response.ok) {
    scrollToTop();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
};

function refreshPage() {
  window.location.reload();
}

const refreshUserData = () => {
  const fetchData = async () => {
    const email = JSON.parse(localStorage.getItem('pcdl.email'));
    const token = JSON.parse(localStorage.getItem('pcdl.token'));

    const res = await fetch(
      `${process.env.REACT_APP_API_PCDLSUB_C}/checksubscriptionstatus`,
      {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Request-Method': 'POST',
          'X-Requested-With': 'XMLHttpRequest',
          
        },
        body: JSON.stringify({ email, token }),
      },
    );

    const data = await res.json();

    const update = JSON.parse(localStorage.getItem('pcdl.user'));

    if (
      update.subscription_name !== data.body.subscription_name
      || update.wallet_balance !== data.body.wallet_balance
    ) {
      update.subscription_name = data.body.subscription_name;
      update.wallet_balance = data.body.wallet_balance;
      localStorage.setItem('pcdl.user', JSON.stringify(update));
    }
  };
  fetchData().catch(console.error);
};

const extendSubscription = async (
  type,
  paymentId,
  amount,
  days,
  gift,
  gift_email,
  code,
  promo = '',
) => {
  const response = await fetch(`${process.env.REACT_APP_API_1BS}payment`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Method': 'POST',
    },
    body: JSON.stringify({
      email: JSON.parse(localStorage.getItem('pcdl.user')).email,
      amount,
      type,
      days,
      gift,
      gift_email,
      code,
      promo,
    }),
  });

  await response.json();
  // console.log(json);
};

const affiliateSignUp = async (email, first_name, ref) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Method': 'POST',
      'X-Requested-With': 'XMLHttpRequest',
      
      'API-TOKEN': process.env.REACT_APP_API_SUB_TOKEN,
    },
    body: JSON.stringify({
      email,
      name: first_name,
      ref,
      product: 1,
    }),
  };

  const response = await fetch(
    'https://pcdlsub.loveworldapis.com/api/affiliate/add',
    requestOptions,
  );

  const json = await response.json();
};

const affiliatePurchase = async (email, first_name, payload, ref) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Method': 'POST',
      'X-Requested-With': 'XMLHttpRequest',
      
      'API-TOKEN': process.env.REACT_APP_API_SUB_TOKEN,
    },
    body: JSON.stringify({
      email,
      name: first_name,
      ref,
      payload,
      product: 2,
    }),
  };

  const response = await fetch(
    'https://pcdlsub.loveworldapis.com/api/affiliate/add',
    requestOptions,
  );

  const json = await response.json();
};

const purchaseAlbum = async (albumID, albumPrice) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Method': 'POST',
      'X-Requested-With': 'XMLHttpRequest',
      
    },
    body: JSON.stringify({
      email: JSON.parse(localStorage.getItem('pcdl.user')).email,
      token: JSON.parse(localStorage.getItem('pcdl.user')).token,
      albumID,
      ProductPrice: albumPrice,
      api_key: process.env.REACT_APP_API,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_PCDLSUB_C}/external/userbuyproduct`,
    requestOptions,
  );

  const json = await response.json();

  // Add album to users library temp
  const x = JSON.parse(localStorage.getItem('pcdl.user')).library;

  const newLibrary = [...x, json.new_purchase];

  const updatedUser = JSON.parse(localStorage.getItem('pcdl.user'));

  updatedUser.library = newLibrary;

  localStorage.setItem('pcdl.user', JSON.stringify(updatedUser));
};

const espeesSubscription = async (e, amount, days, tier, setError, setShow) => {
  e.preventDefault();

  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);

  const user = JSON.parse(localStorage.getItem('pcdl.user'));

  let affiliate = '';
  try {
    affiliate = JSON.parse(localStorage.getItem('pcdl.user')).affiliate;
  } catch {
    affiliate = '';
  }

  if (affiliate) {
    affiliatePurchase(
      JSON.parse(localStorage.getItem('pcdl.user')).email,
      JSON.parse(localStorage.getItem('pcdl.user')).first_name,
      tier,
      affiliate,
    );
  }

  async function storeTransaction() {
    return fetch(`${process.env.REACT_APP_API_PCDLSUB_A}16`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        'x-requested-with': '',
        'x-requested-by': '',
        
      },
      body: JSON.stringify({
        TransactionId: array[0].toString(36),
        Type: 'Espees',
        Frequency: 'One Time',
        UserId: JSON.parse(localStorage.getItem('pcdl.user')).token,
        UserEmail: JSON.parse(localStorage.getItem('pcdl.user')).email,
        CreationDate: Date.now(),
        amount,
        days,
        plan: tier,
        affiliate,
      }),
    }).then((data) => data.json());
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_PCDLSUB_C}/external/userbuysubscription`,
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        'X-Requested-With': 'XMLHttpRequest',
        
      },
      body: JSON.stringify({
        email: `${user.email}`,
        token: `${user.token}`,
        days,
        amount,
        subscription_type: tier,
        api_key: `${process.env.REACT_APP_API}`,
      }),
    },
  );

  const data = await response.json();

  if (data.statusCode === 413) {
    setError('inbalance');
  } else if (data.statusCode === 200) {
    setError('complete');
    storeTransaction();
    setTimeout(() => {
      window.location.replace('/');
      setShow(false);
      setError('');
    }, 2000);
  }
};

const espeesSA = async (e, amount, days, tier, gift_email, setError, setShow) => {
  e.preventDefault();

  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);

  const user = JSON.parse(localStorage.getItem('pcdl.user'));

  console.log(gift_email);

  const response = await fetch(
    `${process.env.REACT_APP_API_PCDLSUB_C}/external/userbuysubscription`,
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        'X-Requested-With': 'XMLHttpRequest',
        
      },
      body: JSON.stringify({
        email: `${user.email}`,
        token: `${user.token}`,
        days: 0,
        amount,
        subscription_type: tier,
        api_key: `${process.env.REACT_APP_API}`,
      }),
    },
  );

  const data = await response.json();

  if (data.statusCode === 413) {
    setError('inbalance');
  } else if (data.statusCode === 200) {
    setError('complete');

    const res = await fetch(`${process.env.REACT_APP_API_1BS}paymentlink`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        
      },
      body: JSON.stringify({
       payment_method: 'custom_payment',
        email: gift_email,
        amount,
        subscription_type: tier,
        days,
        code: 'SA_Special',
      }),
    });
await res.json();
setTimeout(() => {
      setShow(false);
      setError('');
    }, 2000);
  }
};

const espeesSubscriptionPromo = async (
  e,
  amount,
  days,
  tier,
  setError,
  setShow,
  promo,
  code,
  setLoading,
) => {
  e.preventDefault();
  setLoading(true);

  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);

  const user = JSON.parse(localStorage.getItem('pcdl.user'));

  let affiliate = '';
  try {
    affiliate = JSON.parse(localStorage.getItem('pcdl.user')).affiliate;
  } catch {
    affiliate = '';
  }

  if (affiliate) {
    affiliatePurchase(
      JSON.parse(localStorage.getItem('pcdl.user')).email,
      JSON.parse(localStorage.getItem('pcdl.user')).first_name,
      tier,
      affiliate,
    );
  }

  async function storeTransaction() {
    return fetch(`${process.env.REACT_APP_API_PCDLSUB_A}16`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        'x-requested-with': '',
        'x-requested-by': '',
        
      },
      body: JSON.stringify({
        TransactionId: array[0].toString(36),
        Type: 'Espees',
        Frequency: 'One Time',
        UserId: JSON.parse(localStorage.getItem('pcdl.user')).token,
        UserEmail: JSON.parse(localStorage.getItem('pcdl.user')).email,
        CreationDate: Date.now(),
        amount,
        days,
        plan: tier,
        affiliate,
        promo,
        code,
      }),
    }).then((data) => data.json());
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_PCDLSUB_C}/external/userbuysubscription`,
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        'X-Requested-With': 'XMLHttpRequest',
        
      },
      body: JSON.stringify({
        email: `${user.email}`,
        token: `${user.token}`,
        days,
        amount,
        subscription_type: tier,
        api_key: `${process.env.REACT_APP_API}`,
      }),
    },
  );

  const data = await response.json();

  // console.log({
  //   email: `${user.email}`,
  //   token: `${user.token}`,
  //   days,
  //   amount,
  //   subscription_type: tier,
  //   api_key: `${process.env.REACT_APP_API}`,
  // });

  if (data.statusCode === 413) {
    setError('inbalance');
  } else if (data.statusCode === 200) {
    setError('complete');
    storeTransaction();
    setTimeout(() => {
      window.location.replace('/');
      setShow(false);
      setError('');
    }, 2000);
  }

  setLoading(false);
};

function capitalize(str) {
  const arr = str.split(' ');
  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2;
}

const countries = [
  // Prioritized Countries
  { code: '+234', name: 'Nigeria', flag: '🇳🇬' },
  { code: '+233', name: 'Ghana', flag: '🇬🇭' },
  { code: '+263', name: 'Zimbabwe', flag: '🇿🇼' },
  { code: '+27', name: 'South Africa', flag: '🇿🇦' },
  { code: '+1', name: 'United States', flag: '🇺🇸' },
  { code: '+44', name: 'United Kingdom', flag: '🇬🇧' },

  // Rest of the World (sorted alphabetically by name)
  { code: '+93', name: 'Afghanistan', flag: '🇦🇫' },
  { code: '+355', name: 'Albania', flag: '🇦🇱' },
  { code: '+213', name: 'Algeria', flag: '🇩🇿' },
  { code: '+376', name: 'Andorra', flag: '🇦🇩' },
  { code: '+244', name: 'Angola', flag: '🇦🇴' },
  { code: '+54', name: 'Argentina', flag: '🇦🇷' },
  { code: '+374', name: 'Armenia', flag: '🇦🇲' },
  { code: '+61', name: 'Australia', flag: '🇦🇺' },
  { code: '+43', name: 'Austria', flag: '🇦🇹' },
  { code: '+994', name: 'Azerbaijan', flag: '🇦🇿' },
  { code: '+973', name: 'Bahrain', flag: '🇧🇭' },
  { code: '+880', name: 'Bangladesh', flag: '🇧🇩' },
  { code: '+375', name: 'Belarus', flag: '🇧🇾' },
  { code: '+32', name: 'Belgium', flag: '🇧🇪' },
  { code: '+229', name: 'Benin', flag: '🇧🇯' },
  { code: '+591', name: 'Bolivia', flag: '🇧🇴' },
  { code: '+387', name: 'Bosnia and Herzegovina', flag: '🇧🇦' },
  { code: '+267', name: 'Botswana', flag: '🇧🇼' },
  { code: '+55', name: 'Brazil', flag: '🇧🇷' },
  { code: '+359', name: 'Bulgaria', flag: '🇧🇬' },
  { code: '+226', name: 'Burkina Faso', flag: '🇧🇫' },
  { code: '+257', name: 'Burundi', flag: '🇧🇮' },
  { code: '+855', name: 'Cambodia', flag: '🇰🇭' },
  { code: '+237', name: 'Cameroon', flag: '🇨🇲' },
  { code: '+1', name: 'Canada', flag: '🇨🇦' },
  { code: '+56', name: 'Chile', flag: '🇨🇱' },
  { code: '+86', name: 'China', flag: '🇨🇳' },
  { code: '+57', name: 'Colombia', flag: '🇨🇴' },
  { code: '+506', name: 'Costa Rica', flag: '🇨🇷' },
  { code: '+385', name: 'Croatia', flag: '🇭🇷' },
  { code: '+357', name: 'Cyprus', flag: '🇨🇾' },
  { code: '+420', name: 'Czech Republic', flag: '🇨🇿' },
  { code: '+45', name: 'Denmark', flag: '🇩🇰' },
  { code: '+253', name: 'Djibouti', flag: '🇩🇯' },
  { code: '+20', name: 'Egypt', flag: '🇪🇬' },
  { code: '+372', name: 'Estonia', flag: '🇪🇪' },
  { code: '+251', name: 'Ethiopia', flag: '🇪🇹' },
  { code: '+358', name: 'Finland', flag: '🇫🇮' },
  { code: '+33', name: 'France', flag: '🇫🇷' },
  { code: '+241', name: 'Gabon', flag: '🇬🇦' },
  { code: '+995', name: 'Georgia', flag: '🇬🇪' },
  { code: '+49', name: 'Germany', flag: '🇩🇪' },
  { code: '+30', name: 'Greece', flag: '🇬🇷' },
  { code: '+502', name: 'Guatemala', flag: '🇬🇹' },
  { code: '+509', name: 'Haiti', flag: '🇭🇹' },
  { code: '+504', name: 'Honduras', flag: '🇭🇳' },
  { code: '+36', name: 'Hungary', flag: '🇭🇺' },
  { code: '+354', name: 'Iceland', flag: '🇮🇸' },
  { code: '+91', name: 'India', flag: '🇮🇳' },
  { code: '+62', name: 'Indonesia', flag: '🇮🇩' },
  { code: '+98', name: 'Iran', flag: '🇮🇷' },
  { code: '+964', name: 'Iraq', flag: '🇮🇶' },
  { code: '+353', name: 'Ireland', flag: '🇮🇪' },
  { code: '+972', name: 'Israel', flag: '🇮🇱' },
  { code: '+39', name: 'Italy', flag: '🇮🇹' },
  { code: '+81', name: 'Japan', flag: '🇯🇵' },
  { code: '+962', name: 'Jordan', flag: '🇯🇴' },
  { code: '+254', name: 'Kenya', flag: '🇰🇪' },
  { code: '+965', name: 'Kuwait', flag: '🇰🇼' },
  { code: '+371', name: 'Latvia', flag: '🇱🇻' },
  { code: '+961', name: 'Lebanon', flag: '🇱🇧' },
  { code: '+231', name: 'Liberia', flag: '🇱🇷' },
  { code: '+370', name: 'Lithuania', flag: '🇱🇹' },
  { code: '+352', name: 'Luxembourg', flag: '🇱🇺' },
  { code: '+261', name: 'Madagascar', flag: '🇲🇬' },
  { code: '+265', name: 'Malawi', flag: '🇲🇼' },
  { code: '+60', name: 'Malaysia', flag: '🇲🇾' },
  { code: '+223', name: 'Mali', flag: '🇲🇱' },
  { code: '+356', name: 'Malta', flag: '🇲🇹' },
  { code: '+222', name: 'Mauritania', flag: '🇲🇷' },
  { code: '+230', name: 'Mauritius', flag: '🇲🇺' },
  { code: '+52', name: 'Mexico', flag: '🇲🇽' },
  { code: '+373', name: 'Moldova', flag: '🇲🇩' },
  { code: '+377', name: 'Monaco', flag: '🇲🇨' },
  { code: '+976', name: 'Mongolia', flag: '🇲🇳' },
  { code: '+212', name: 'Morocco', flag: '🇲🇦' },
  { code: '+95', name: 'Myanmar', flag: '🇲🇲' },
  { code: '+977', name: 'Nepal', flag: '🇳🇵' },
  { code: '+31', name: 'Netherlands', flag: '🇳🇱' },
  { code: '+64', name: 'New Zealand', flag: '🇳🇿' },
  { code: '+47', name: 'Norway', flag: '🇳🇴' },
  { code: '+92', name: 'Pakistan', flag: '🇵🇰' },
  { code: '+507', name: 'Panama', flag: '🇵🇦' },
  { code: '+595', name: 'Paraguay', flag: '🇵🇾' },
  { code: '+51', name: 'Peru', flag: '🇵🇪' },
  { code: '+63', name: 'Philippines', flag: '🇵🇭' },
  { code: '+48', name: 'Poland', flag: '🇵🇱' },
  { code: '+351', name: 'Portugal', flag: '🇵🇹' },
  { code: '+974', name: 'Qatar', flag: '🇶🇦' },
  { code: '+40', name: 'Romania', flag: '🇷🇴' },
  { code: '+7', name: 'Russia', flag: '🇷🇺' },
  { code: '+966', name: 'Saudi Arabia', flag: '🇸🇦' },
  { code: '+221', name: 'Senegal', flag: '🇸🇳' },
  { code: '+65', name: 'Singapore', flag: '🇸🇬' },
  { code: '+421', name: 'Slovakia', flag: '🇸🇰' },
  { code: '+386', name: 'Slovenia', flag: '🇸🇮' },
  { code: '+82', name: 'South Korea', flag: '🇰🇷' },
  { code: '+34', name: 'Spain', flag: '🇪🇸' },
  { code: '+94', name: 'Sri Lanka', flag: '🇱🇰' },
  { code: '+46', name: 'Sweden', flag: '🇸🇪' },
  { code: '+41', name: 'Switzerland', flag: '🇨🇭' },
  { code: '+963', name: 'Syria', flag: '🇸🇾' },
  { code: '+886', name: 'Taiwan', flag: '🇹🇼' },
  { code: '+255', name: 'Tanzania', flag: '🇹🇿' },
  { code: '+66', name: 'Thailand', flag: '🇹🇭' },
  { code: '+228', name: 'Togo', flag: '🇹🇬' },
  { code: '+216', name: 'Tunisia', flag: '🇹🇳' },
  { code: '+90', name: 'Turkey', flag: '🇹🇷' },
  { code: '+256', name: 'Uganda', flag: '🇺🇬' },
  { code: '+380', name: 'Ukraine', flag: '🇺🇦' },
  { code: '+971', name: 'United Arab Emirates', flag: '🇦🇪' },
  { code: '+58', name: 'Venezuela', flag: '🇻🇪' },
  { code: '+84', name: 'Vietnam', flag: '🇻🇳' },
  { code: '+967', name: 'Yemen', flag: '🇾🇪' },
  { code: '+260', name: 'Zambia', flag: '🇿🇲' },
];

export {
  scrollToTop,
  getIndex,
  handleCheckboxChange,
  isPresent,
  setTypeFunc,
  showNotification,
  deleteMessage,
  deleteSeries,
  deleteSeason,
  refreshPage,
  refreshUserData,
  extendSubscription,
  capitalize,
  purchaseAlbum,
  espeesSubscription,
  espeesSubscriptionPromo,
  affiliateSignUp,
  affiliatePurchase,
  espeesSA,
  duration,
  countries,
};
